<template>
  
<div class="el-grid">
  <div class="a">a</div>
  <div class="b">b</div>
</div>


</template>

<script>
export default {

}
</script>

<style>

</style>